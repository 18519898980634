import { SafePipe } from './safe.pipe';
import { FormsModule } from '@angular/forms';
// inbuild imports
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { LoaderComponent } from './loader/loader.component';

// external imports




@NgModule({
  declarations: [
    LoaderComponent,
    SafePipe
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    NgSelectModule
  ],
  exports: [
    RouterModule,
    CommonModule,
    RouterModule,
    FormsModule,
    NgSelectModule,
    LoaderComponent,
    SafePipe
  ]
})
export class SharedModule { }
