import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


// local imports
import { HomeComponent } from './home/home.component';
import { GuestInfoComponent } from './guest-info/guest-info.component';
import { BillingInfoComponent } from './billing-info/billing-info.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { CancelListComponent } from './cancel-list/cancel-list.component';



const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'guest-info',
    component: GuestInfoComponent
  },
  {
    path: 'billing-info',
    component: BillingInfoComponent
  },
  {
    path: 'confirmation',
    component: ConfirmationComponent
  },
  {
    path: 'cancelBookingList',
    component: CancelListComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
