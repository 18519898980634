import { CommonService } from './../common-service/common.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
HotelId:number;
  constructor(
    public commonService: CommonService
  ) {   
   
  }

  ngOnInit(): void {
   
  }

}
