import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '../common-service/common.service';

@Component({
  selector: 'app-billing-info',
  templateUrl: './billing-info.component.html',
  styleUrls: ['./billing-info.component.css']
})
export class BillingInfoComponent implements OnInit {
  bookedRoom: any={};
  roomPrice: any;
  dealsData: any;
  selecteddeals : any=[]
  totalDealCharge: number =0;
  totalOccupany : number =0
  priceData: any;
  pricePopupToShow: boolean=false;
  currencySymbol: any;
  conversionRate: number;
  constructor(
    public commonService : CommonService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.bookedRoom = this.commonService.bookedRoom
    if(this.bookedRoom && this.bookedRoom.length > 0){
      this.bookedRoom.forEach((el)=>{
        el.occupancy.forEach((elem)=>{
          this.totalOccupany += +elem.adults
        })
      })
    }
    this.dealsData = this.commonService.dealsData
    this.commonService.searchStatus().subscribe((obj)=>{
      this.currencySymbol = obj.symbol
      this.conversionRate = +obj.rate?+obj.rate:1
    })
    if(this.dealsData&&this.dealsData.length>0){
    this.dealsData.forEach((d)=>{
      d['added']=false
    })
    }
  }
  transformCount(length: number, offset: number = 0): number[] {
    if (!length) {
      return [];
    }
    const array = [];
    for (let n = 0; n < length; ++n) {
      array.push(offset + n);
    }
    return array;
  }
  book(){
    this.commonService.totalDealCharge = this.totalDealCharge
    this.commonService.selectedDeals= this.selecteddeals
    this.router.navigate(['guest-info'])
  }

  addDeal(deal,i){
    this.dealsData[i].added= true
    let totalTaxRateArray = deal.TaxDetails.map(x => x.TotalTaxRate)
    let totalTaxRate = totalTaxRateArray.reduce((total, num) => total + num);
    let totalTax = (deal.Price)*(totalTaxRate)/100
    let obj = {
      Id: 0,
      ServiceId: deal.Id,
      TaxTypeId: 0,
      TaxProcessId: 20,
      Qty: this.totalOccupany,
      Price: deal.Price,
      Name: deal.Name,
      TotalTax:totalTax  ,
    }
    this.selecteddeals.push(obj)
    this.dealTotal()
  }

  QtySelect(event,deal){
    let service = this.selecteddeals.filter((d) => d.ServiceId === deal.Id) ;
    service[0].Qty = +(event.target.value)
    this.dealTotal()

  }

  removeDeal(deal,ind){
    this.dealsData[ind].added = false
    let index
    for ( var i in this.selecteddeals){
      if (this.selecteddeals[i].ServiceId == deal.Id) {
        index = i
      }
    }
    this.selecteddeals.splice(index, 1)
    this.dealTotal()
  }

  dealTotal(){
    this.totalDealCharge = 0
    this.selecteddeals.forEach((el) => {
    this.totalDealCharge =this.totalDealCharge + (el.Price+el.TotalTax) *el.Qty
  });
  this.totalDealCharge  = +((this.totalDealCharge).toFixed(2))
}

pricePopup(booking){
  this.priceData ={}
  this.pricePopupToShow = true
  this.priceData = booking
}
}
