<section class="rooms-information" style="padding-top: 70px">
  <div class="container py-4">
    <div class="row mb-4">
      <div class="col-md-4 left-column">
        <div class="card mb-3 mb-md-0">
          <div class="card-header">
            <h2 class="mb-0 pb-0">Your booking summary</h2>
            <div class="sum-htl-addr">
              <h5 class="mb-1">{{commonService?.Branchlist?.HotelName}}</h5>
              <p class="mb-0"><i
                  class="fa fa-map-marker"></i>{{commonService?.Branchlist?.Address}},{{commonService?.Branchlist?.CityName}},{{commonService?.Branchlist?.StateName}},{{commonService?.Branchlist?.CountryName}}
              </p>
              <p class="mb-0"><i *ngIf="commonService?.Branchlist?.MobileNo?.length>0" class="fa fa-phone"
                  aria-hidden="true"></i> {{commonService?.Branchlist?.MobileNo}}</p>
              <p class="mb-0"><i *ngIf="commonService?.Branchlist?.Email?.length>0" class="fa fa-envelope"
                  aria-hidden="true"></i> {{commonService?.Branchlist?.Email}}</p>
            </div>
          </div>
          <div class="card-body p-0">
            <div class="payment-card p-0">
              <h3 class="mb-0 pb-0"> <span>Booking Type:</span>{{commonService?.bookingType}}</h3>
              <div class="pay-chkInOut_wrap">
                <p class="pay-chkInOut"><b>Check-In</b><span>{{commonService?.arrival}}-
                    {{commonService?.CheckInOutTime?.RangeFrom}}</span></p>
                <p class="pay-chkInOut"><b>Check-Out</b><span>{{commonService?.bookingType==='Day
                    Room'?commonService.arrival:commonService?.departure}}-
                    {{commonService?.CheckInOutTime1?.RangeFrom}}</span></p>
              </div>
              <div class="pay-rm_detail">
                <div *ngFor="let room of bookedRoom;let i=index;">
                  <div *ngFor=" let occupancy of room.occupancy; let j =index;">
                    <div class="pax-detail">
                      <b>Room {{j+1}} </b><br />
                      Rateplan Name : {{room.RoomPlan}}<br>
                      <b>Pax Details :</b>{{occupancy.adults}} Adults , {{occupancy.childs}} Child
                      <ul class="pay-rm-rm-list">
                        <li> Room Charges</li>
                        <li>
                          <span class="price">
                            <span class="foreignrates vres-prices onlyroomcharge_0">{{currencySymbol}}
                              {{(room.Fare/conversionRate).toFixed(2)}}</span>
                          </span>
                        </li>
                        <li>
                          <span>Taxes &amp; Fees</span>
                        </li>
                        <li>
                          <span class="price">
                            <span class="foreignrates vres-prices roomtax_0">{{currencySymbol}}
                              {{(room.Tax/conversionRate).toFixed(2)}}</span>
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div *ngIf="commonService?.selectedDeals?.length>0" style="color:#c66161">Extra Charges:</div>
                <div class="mb-0 extra-rm" *ngFor="let deals of commonService?.selectedDeals; let i  = index;">
                  <span class="ext-rm-nm">{{deals.Name}}&nbsp;</span><br>
                  <div class="d-flex">
                    <span>Pax: {{deals.Qty}} x <span> {{(deals.Price/conversionRate).toFixed(2)}} </span></span>
                    <span class="">Tax: {{((deals.TotalTax*deals.Qty)/conversionRate).toFixed(2)}}</span>
                    <span class="ml-auto">{{currencySymbol}}
                      {{(((deals.TotalTax+deals.Price)*deals.Qty)/conversionRate).toFixed(2)}}</span>
                  </div>
                </div>
              </div>
              <div class="total-paybleRm pt-3" id="payment_data_block">
                <p>
                  <span>Total Room Charges
                    <span *ngIf="discount===0" class="price">
                      <span class="foreignrates vres-prices" id="tot_roomcharge">{{currencySymbol}}
                        {{(totalFare/conversionRate).toFixed(2)}}</span>
                    </span>
                    <span *ngIf="discount>0" class="price">
                      <span class="foreignrates vres-prices" id="tot_roomcharge"><del>{{currencySymbol}}
                          {{(totalFare/conversionRate).toFixed(2)}}</del>&nbsp;{{(fareAfterDiscount/conversionRate).toFixed(2)}}</span>
                    </span>
                  </span>
                  <span>Total Taxes
                    <span *ngIf="discount===0" class="price">
                      <span class="foreignrates vres-prices" id="taxes">{{currencySymbol}}
                        {{(totalTax/conversionRate).toFixed(2)}}</span>
                    </span>
                    <span *ngIf="discount>0" class="price">
                      <span class="foreignrates vres-prices" id="taxes"><del>{{currencySymbol}}
                          {{(totalTax/conversionRate).toFixed(2)}}</del>&nbsp;{{(taxAfterDiscount/conversionRate).toFixed(2)}}</span>
                    </span>
                  </span>
                  <span *ngIf="commonService?.selectedDeals?.length>0">Extra Services
                    <span class="price">
                      <span class="foreignrates vres-prices" id="taxes">{{currencySymbol}}
                        {{(commonService.totalDealCharge/conversionRate).toFixed(2)}}</span>
                    </span>
                  </span>
                  <span>Total Price (Inc. Of Taxes)
                    <span class="price">
                      <span class="foreignrates vres-prices GrandTotal" id="grandtotal">{{currencySymbol}}
                        {{(totalCharge/conversionRate).toFixed(2)}}</span>
                    </span>
                  </span>
                </p>
                <h3 id="temp-price">
                  <div class="curr_div d-flex">
                    <span id="totalpaymentcaption">Total Payable Amount</span>
                    <span class="ml-auto mr-0">
                      <b class="foreignrates GrandTotal" id="totalpay">{{currencySymbol}}
                        {{(totalCharge/conversionRate).toFixed(2)}}</b>
                    </span>
                  </div>
                </h3>
              </div>
              <div *ngIf="appliedCode === false" class="total-paybleRm spe-ofer-sect" id="choosepromocode">
                <span class="promo-ofr-title">OFFERS :</span>
                <div *ngFor="let voucher of voucherList" class="promo-offer-list">
                  <label for="{{voucher.CouponCode}}">
                    <input id="{{voucher.CouponCode}}" [value]='voucher.CouponCode' type="radio" name="voucher"
                      [(ngModel)]="promo">
                    {{voucher.CouponCode}}
                  </label>
                </div>
              </div>
              <div *ngIf="appliedCode === false" class="promo-form-payment tipso-tooltip tipso_style mb-3"
                data-tipso="Discount Code / Voucher" id="promocodefrm">
                <input id="pcodebill" [(ngModel)]="promo" type="text" placeholder="Enter Promotional code"
                  name="promocode" />
                <button id="applypromocode" type="button" class="vres-btn btn btn-primary" (click)="applyPromo()">
                  Apply
                </button>
              </div>
              <ng-container *ngIf="appliedCode === true" class="vrt-links promo-code-sved saved_promo"
                id="appliedpromocode">
                <div class="promo-tag">
                  <i class="fa fa-check"></i> Applied Promotional Code
                  <a title="Promotional Discount" class="just-bk_info" id="billingpromocode">&nbsp;{{promo}}</a>
                </div>
              </ng-container>
              <ng-container *ngIf="appliedCode === true" id="saved_onapplypromo"
                class="vrt-links promo-code-sved saved_promo">
                <div class="promo-tag">
                  <span><i class="fa fa-check"></i> You Total Saving :
                    <span id="promo_ograndtotal" class="foreignrates">&nbsp;{{currencySymbol}}
                      {{(discount/conversionRate).toFixed(2)}}</span>
                  </span>
                </div>
              </ng-container>
              <div id="saved_onapplypromo" class="vrt-links promo-code-sved saved_promo">
                <div id="lookinguser" class="user_alert" align="center"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <div class="container">
          <div class="row add-extra-wrap pt-3">
            <div class="add-services_wrap">
              <div class="row mb-4 ml-3 mr-3 guest-information">
                <div class="col-md-12 pl-2 bdr-bttom pb-3">
                  <div class="guest-title mb-3"> Guest Information</div>
                  <form class="form-inline select-input-w-same" #guestInfo="ngForm">
                    <div class="w-100 d-block mb-3">
                      <label for="email" class="d-block w-100">Guest Name:</label>
                      <div class="d-flex">
                        <select class="form-control prefix_val mr-2" name="prefix" [(ngModel)]="prefix"
                          [ngClass]="{ 'ng-select-invalid': submitClick && !prefix }">
                          <option *ngFor="let opt of commonService?.hotelDetails?.NameTitleList;" [value]="opt.Name">
                            {{opt.Name}}</option>
                        </select>
                        <input type="text" onkeydown="return /[a-zA-Z]/i.test(event.key)" pattern="'^[a-zA-Z \-\']$'"
                          class="form-control flex-w-full" placeholder="Full Name" id="guestNameId" name="guestName"
                          [(ngModel)]="guestName" [ngClass]="{
                          'error': submitClick &&  !guestName
                        }">
                      </div>
                    </div>
                    <div class="w-100 d-block mb-3">
                      <label for="email" class="d-block w-100">Mobile:</label>
                      <div class="d-flex contry-val-gp">
                        <select class="form-control mr-2 prefix_cont-val">
                          <option>India (+91)</option>
                          <option>Afghanistan (+93) </option>
                          <option>Albania (+355) </option>
                          <option>Algeria (+213)</option>
                          <option>American Samoa (+1-684) </option>
                          <option>Andorra (+376) </option>
                          <option>Angola (+244) </option>
                          <option> Anguilla (+1-264) </option>
                          <option>Antarctica (+672) </option>
                          <option>Antigua and Barbuda (+1-268) </option>
                          <option>Argentina (+54) </option>
                          <option>Armenia (+374) </option>
                          <option>Aruba (+297) </option>
                          <option>Australia (+61 ) </option>
                          <option>Austria (+43) </option>
                          <option>Azerbaijan 994) </option>
                          <option>Bahamas 1-242 ) </option>
                          <option>Bahrain (+973 ) </option>
                          <option> Bangladesh (+880) </option>
                          <option> Barbados (+1-246) </option>
                          <option>Belarus (+375) </option>
                          <option>Belgium (+32) </option>
                          <option>Belize (+501) </option>
                          <option>Cambodia (+855 ) </option>
                          <option>Cameroon (+237) </option>
                          <option>Canada (+1) </option>
                          <option> Cape Verde (+238 ) </option>
                          <option>Cayman Islands (+1-345 ) </option>
                          <option>Central African Republic (+236) </option>
                          <option>Chad (+235) </option>
                          <option>Chile (+56) </option>
                          <option>China (+86) </option>
                          <option>Christmas Island (+61) </option>
                          <option>Cocos Islands (+61 ) </option>
                          <option> Colombia (+57) </option>
                          <option> Comoros (+269 ) </option>
                          <option>Cook Islands (+682 ) </option>
                          <option>Costa Rica (+506 ) </option>
                          <option>Croatia (+385) </option>
                          <option>Cuba (+53) </option>
                          <option>Curacao (+599) </option>
                          <option>Cyprus (+357 ) </option>
                          <option>Czech Republic (+420) </option>
                          <option>Democratic Republic of the Congo (+243 ) </option>
                          <option>Denmark (+45) </option>
                          <option>Djibouti (+253) </option>
                          <option>Dominica (+1-767) </option>
                          <option>Dominican Republic (+1-809) </option>
                          <option>East Timor (+670 ) </option>
                          <option>Ecuador (+593) </option>
                          <option>Egypt (+20) </option>
                          <option>El Salvador (+503) </option>
                          <option>Equatorial Guinea (+240) </option>
                          <option>Eritrea (+291) </option>
                          <option>Estonia (+372) </option>
                          <option>Ethiopia (+251) </option>
                          <option>Falkland Islands (+500) </option>
                          <option>Faroe Islands (+298) </option>
                          <option>Fiji (+679 ) </option>
                          <option>Finland (+358) </option>
                          <option>France (+33) </option>
                          <option>French Polynesia (+689) </option>
                          <option>Gabon (+241 ) </option>
                          <option>Gambia (+220) </option>
                          <option>Georgia (+995) </option>
                          <option>Germany (+49) </option>
                          <option>Ghana (+233) </option>
                          <option>Gibraltar (+350) </option>
                          <option>Greece (+30) </option>
                          <option>Greenland (+299) </option>
                          <option>Grenada (+1-473) </option>
                          <option>Guam (+1-671) </option>
                          <option>Guatemala (+502) </option>
                          <option>Guernsey (+44-1481) </option>
                          <option>Guinea (+224) </option>
                          <option>Guinea-Bissau (+245) </option>
                          <option>Guyana (+592) </option>
                          <option>Haiti (+509 ) </option>
                          <option>Honduras (+504) </option>
                          <option>Hong Kong (+852) </option>
                          <option>Hungary (+36) </option>
                          <option>Iceland (+354) </option>

                          <option>Indonesia (+62) </option>
                          <option>Iran (+98) </option>
                          <option>Iraq (+964) </option>
                          <option>Ireland (+353) </option>
                          <option>Isle of Man (+44-1624) </option>
                          <option>Israel (+972) </option>
                          <option>Italy (+39) </option>
                          <option>Ivory Coast (+225) </option>
                          <option>Jamaica (+1-876) </option>
                          <option>Japan (+81) </option>
                          <option>Jersey (+44-1534) </option>
                          <option>Jordan (+962) </option>
                          <option>Kazakhstan (+7) </option>
                          <option>Kenya (+254) </option>
                          <option>Kiribati (+686) </option>
                          <option>Kosovo (+383) </option>
                          <option>Kuwait (+965) </option>
                          <option>Kyrgyzstan (+996) </option>
                          <option>Laos (+856) </option>
                          <option>Latvia (+371) </option>
                          <option>Lebanon (+961) </option>
                          <option>Lesotho (+266) </option>
                          <option>Liberia (+231) </option>
                          <option>Libya (+218) </option>
                          <option>Liechtenstein (+423) </option>
                          <option>Lithuania (+370) </option>
                          <option>Luxembourg (+352) </option>
                          <option>Macau (+853) </option>
                          <option>Macedonia (+389) </option>
                          <option>Madagascar (+261) </option>
                          <option>Malawi (+265) </option>
                          <option>Malaysia (+60) </option>
                          <option>Maldives (+960) </option>
                          <option>Mali (+223) </option>
                          <option>Malta (+356) </option>
                          <option>Marshall Islands (+692) </option>
                          <option>Mauritania (+222) </option>
                          <option>Mauritius (+230) </option>
                          <option>Mayotte (+262) </option>
                          <option>Mexico (+52) </option>
                          <option>Micronesia (+691) </option>
                          <option>Moldova (+373) </option>
                          <option>Monaco (+377) </option>
                          <option>Mongolia (+976) </option>
                          <option>Montenegro (+382) </option>
                          <option>Montserrat (+1-664) </option>
                          <option>Morocco (+212) </option>
                          <option>Mozambique (+258) </option>
                          <option>Myanmar (+95) </option>
                          <option>Namibia (+264) </option>
                          <option>Nauru (+674) </option>
                          <option>Nepal (+977) </option>
                          <option>Netherlands (+31) </option>
                          <option>Netherlands Antilles (+599) </option>
                          <option>New Caledonia (+687) </option>
                          <option>New Zealand (+64) </option>
                          <option>Nicaragua (+505) </option>
                          <option>Niger (+227) </option>
                          <option>Nigeria (+234 ) </option>
                          <option>Niue (+683) </option>
                          <option>North Korea (+850 ) </option>
                          <option>Northern Mariana Islands (+1-670 ) </option>
                          <option>Norway (+47) </option>
                          <option>Oman (+968) </option>
                          <option>Pakistan (+92) </option>
                          <option>Palau (+680 ) </option>
                          <option>Palestine (+970) </option>
                          <option>Panama (+507) </option>
                          <option>Papua New Guinea (+675) </option>
                          <option>Paraguay (+595) </option>
                          <option>Peru (+51) </option>
                          <option>Philippines (+63) </option>
                          <option>Pitcairn (+64) </option>
                          <option>Poland (+48 ) </option>
                          <option>Portugal (+351) </option>
                          <option>Puerto Rico (+1-787) </option>
                          <option>Qatar (+974) </option>
                          <option>Republic of the Congo (+242) </option>
                          <option>Reunion (+262) </option>
                          <option>Romania (+40) </option>
                          <option>Russia (+7) </option>
                          <option>Rwanda (+250 ) </option>
                          <option>Saint Barthelemy (+590) </option>
                          <option>Saint Helena (+290 ) </option>
                          <option>Saint Kitts and Nevis (+1-869) </option>
                          <option>Saint Lucia (+1-758) </option>
                          <option>Saint Martin (+590) </option>
                          <option>Saint Pierre and Miquelon (+508) </option>
                          <option>Saint Vincent and the Grenadines (+1-784) </option>
                          <option>Samoa (+685) </option>
                          <option>San Marino (+378 ) </option>
                          <option>Sao Tome and Principe (+239) </option>
                          <option>Saudi Arabia (+966) </option>
                          <option>Senegal (+221) </option>
                          <option>Serbia (+381) </option>
                          <option>Seychelles (+248) </option>
                          <option>Sierra Leone (+232 ) </option>
                          <option>Singapore (+65 ) </option>
                          <option>Sint Maarten (+1-721) </option>
                          <option>Slovakia (+421) </option>
                          <option>Slovenia (+386) </option>
                          <option>Solomon Islands (+677) </option>
                          <option>Somalia (+252) </option>
                          <option>South Africa (+27) </option>
                          <option>South Korea (+82) </option>
                          <option>South Sudan (+211) </option>
                          <option>Spain (+34) </option>
                          <option>Sri Lanka (+94) </option>
                          <option>Sudan (+249) </option>
                          <option>Suriname (+597) </option>
                          <option>Svalbard and Jan Mayen (+47 SJ / SJM 2,550 62,049
                          <option>Swaziland (+268) </option>
                          <option>Sweden (+46) </option>
                          <option>Switzerland (+41) </option>
                          <option>Syria (+963) </option>
                          <option>Taiwan (+886) </option>
                          <option>Tajikistan (+992) </option>
                          <option>Tanzania (+255) </option>
                          <option>Thailand (+66) </option>
                          <option>Togo (+228) </option>
                          <option>Tokelau (+690) </option>
                          <option>Tonga (+676) </option>
                          <option>Trinidad and Tobago (+1-868) </option>
                          <option>Tunisia (+216) </option>
                          <option>Turkey (+90) </option>
                          <option>Turkmenistan (+993) </option>
                          <option>Turks and Caicos Islands(+1-649)</option>
                          <option>Tuvalu (+88)</option>
                          <option>U.S. Virgin Islands (+1-340)</option>
                          <option>Uganda (+256)</option>
                          <option>Ukraine (+380)</option>
                          <option>United Arab Emirates (+971)</option>
                          <option>United Kingdom (+44)</option>
                          <option>United States (+1)</option>
                          <option>Uruguay (+598 )</option>
                          <option>Uzbekistan (+998)</option>
                          <option>Vanuatu (+678)</option>
                          <option>Vatican (+379)</option>
                          <option>Venezuela (+58)</option>
                          <option>Vietnam (+84)</option>
                          <option>Wallis and Futuna (+681)</option>
                          <option>Western Sahara (+212)</option>
                          <option>Yemen (+967)</option>
                          <option>Zambia (+260)</option>
                          <option>Zimbabwe (+263)</option>
                            <!-- <option>Usa (+1)</option>
                          <option>Afganistan (+93)</option>
                          <option>Australia (+61)</option>
                          <option>Canada (+1)</option>
                          <option>Colombia (+57)</option>
                          <option>United Arab Emirates (+971)</option>
                          <option>United Kingdom (+44)</option>
                          <option>Brazil (+55)</option> -->
                        </select>
                        <input type="number" class="form-control flex-w-full" placeholder="Enter Mobile No"
                          id="guestMobileNoId" name="mobileNo" [(ngModel)]="mobileNo" [ngClass]="{
                          'error': submitClick &&  !mobileNo
                        }">
                      </div>
                    </div>
                    <div class="w-100 d-block mb-3">
                      <label for="emailAddress" class="d-block w-100">Email:</label>
                      <input #emailAdd="ngModel" type="email" class="form-control w-100" placeholder="Email address"
                        id="name" name="emailAddress" [(ngModel)]="email" required
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" [ngClass]="{'error': submitClick &&  !email}">
                      <p class="text-danger" *ngIf="emailAdd.touched && emailAdd.errors?.required">Email is a required
                        field!</p>
                      <p class="text-danger" *ngIf="emailAdd.touched && emailAdd.errors?.pattern">This is not a valid
                        Email!!!</p>
                      <p class="sub-tag">Your voucher will be sent to this email address</p>
                    </div>
                  </form>
                </div>
              </div>
              <div class="row mb-4 ml-3 mr-3 payment-option">
                <div class="col-md-12 pl-2">
                  <ul class="nav nav-tabs">
                    <li class="nav-item">
                      <a class="nav-link active" role="tab" data-toggle="tab" data-target="#Booking">Terms-Condition</a>
                    </li>
                  </ul>
                  <div class="tab-content">
                    <div class="tab-pane active" id="Booking"><br>
                      <h4> {{commonService?.Branchlist?.HeadingTearmandcondition}}</h4>
                      <p [innerHTML]="commonService?.Branchlist?.Tearmandcondition"></p>
                      <!-- <p> {{commonService?.Branchlist?.Tearmandcondition}}</p> -->
                      <br>
                      <h4> {{commonService?.Branchlist?.HeadingDebitTearms}}</h4>
                      <p [innerHTML]="commonService?.Branchlist?.DebitTearms"></p>
                      <!-- <p> {{commonService?.Branchlist?.DebitTearms}}</p> -->
                      <div class="custom-control custom-checkbox mr-sm-2">
                        <input type="checkbox" class="custom-control-input" id="customControlAutosizing" name="termns"
                          [(ngModel)]="terms" [ngClass]="{
                        
                          'error': submitClick &&  !terms
                        }">
                        <label class="custom-control-label" for="customControlAutosizing">I acknowledge and accept the
                          Terms of Cancellation Policy & Hotel Policy . <span class="text-danger">*</span> </label>
                        <div class="row">
                          <div class="col-md-4">
                            <img src="../../assets/images/comodo_secure_100x85_white.png" class="ml-0" alt="">
                          </div>

                          <div class="col-md-8 text-right">
                            <div (click)="submit()" class="btn btn-primary ml-auto mt-3 text-white">Confirm and Pay
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane fade" id="pay"><br>
                      <h4> Credit Card Information </h4>
                      <form>
                        <div class="form-group row">
                          <label for="staticEmail" class="col-sm-2 col-form-label">Name of Card Holder</label>
                          <div class="col-sm-10">
                            <input type="text" class="form-control" id="cardHolderNameId" placeholder="">
                          </div>
                        </div>
                        <div class="form-group row">
                          <label for="staticEmail" class="col-sm-2 col-form-label">Credit Card Number</label>
                          <div class="col-sm-10">
                            <input type="text" class="form-control" id="creditCardNumberId" placeholder="">
                          </div>
                        </div>
                        <div class="form-group row">
                          <label for="staticEmail" class="col-sm-2 col-form-label">Expiry Date</label>
                          <div class="col-sm-10">
                            <div class="form-row">
                              <div class="form-group col-md-4">
                                <select id="monthId" class="form-control">
                                  <option selected>MM</option>
                                  <option>...</option>
                                </select>
                              </div>
                              <div class="form-group col-md-4">
                                <select id="yearId" class="form-control">
                                  <option selected>YY</option>
                                  <option>...</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label for="staticEmail" class="col-sm-2 col-form-label">Verification Code</label>
                          <div class="col-sm-10">
                            <input type="text" class="form-control" id="verificationCodeId" placeholder="Name">
                          </div>
                        </div>
                        <div class="form-group row">
                          <label for="staticEmail" class="col-sm-2 col-form-label">Cards accepted at this hotel</label>
                          <div class="col-sm-10">
                            <img src="../../assets/images/cards.jpg" alt="">
                          </div>
                        </div>
                        <div class="form-group row">
                          <label for="inputPassword" class="col-sm-2 col-form-label">Password</label>
                          <div class="col-sm-10">
                            <input type="password" class="form-control" id="inputPassword" placeholder="Password">
                          </div>
                        </div>
                      </form>

                      <h4> {{commonService?.Branchlist?.HeadingDebitTearms}}</h4>
                      <p> {{commonService?.Branchlist?.DebitTearms}}</p>
                      <div class="custom-control custom-checkbox mr-sm-2">
                        <input type="checkbox" class="custom-control-input" id="customControlAutosizing" name="termns"
                          [(ngModel)]="terms" [ngClass]="{
                          'error': submitClick &&  !mobileNo
                        }">
                        <label class="custom-control-label" for="customControlAutosizing">I acknowledge and accept the
                          Terms of Cancellation Policy & Hotel Policy . * </label>
                        <div class="row">
                          <div class="col-md-4">
                            <img src="../../assets/images/comodo_secure_100x85_white.png" class="ml-0" alt="">
                          </div>
                          <div class="col-md-8 text-right">
                            <div (click)="submit()" class="btn btn-warning ml-auto mt-3 text-white">Confirm and Pay
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>