import { ToastrCustomService } from './toastr.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { share, tap } from 'rxjs/operators'
import { TokenService } from './token.service'
import { Router } from '@angular/router'
import { EMPTY } from 'rxjs'

@Injectable({
  providedIn: 'root'
})

export class BaseServices {

  constructor(
    private http: HttpClient,
    private tokenService: TokenService,
    private router: Router,
    private toster: ToastrCustomService
    ) { }

  private setHeaders(): HttpHeaders {
    const headersConfig = {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
      headersConfig['X-San-Jwt'] = `${ window.sessionStorage['token'] && window.sessionStorage['token']!== "undefined" ?window.sessionStorage['token']:window.sessionStorage['Token']}`,
      headersConfig['Authorization'] = `${ window.sessionStorage['token'] && window.sessionStorage['token']!== "undefined" ?window.sessionStorage['token']:window.sessionStorage['Token']}`
    return new HttpHeaders(headersConfig)
  }


  private userHeaderToken(): HttpHeaders {
    const headersConfig = {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }     
      headersConfig['Authorization'] = `${ window.sessionStorage['user_token']}`
    return new HttpHeaders(headersConfig)
  }
  getRequest(path: string): Observable<any> {
    var usetoken = window.sessionStorage['token'] && window.sessionStorage['token']!== "undefined" ?window.sessionStorage['token']:window.sessionStorage['Token'];
  	// if(typeof usetoken === "undefined"){
  	// 	return EMPTY;
    // }
    // else{
  		return this.http.get(path, { headers: this.setHeaders() }).pipe(share())
  	// }
  }

  putRequest(path: string): Observable<any> {
    return this.http.put(path,null, { headers: this.setHeaders() }).pipe(share())
  }

  postRequest(path: any, params: any): Observable<any> {
    const body = params
    return this.http.post(path, JSON.stringify(body), { headers: this.setHeaders() })
  }
  deleteRequest(path: string, body?: any): Observable<any> {
    if (body) {
      return this.http.request(
        'delete', path, { body: body, headers: this.setHeaders() }
      ).pipe(tap((res) => {
        if (res.Status !== 0) {
          this.toster.showError(res.Description, '')
          throw new Error(res.Description);
        }
      }))
    } else {
      return this.http.delete(
        path, {
          headers: this.setHeaders()
        }
      ).pipe(tap((res) => {
        if (res.Status !== 0) {
          this.toster.showError(res.Description, '')
          throw new Error(res.Description);
        }
      }))
    }
  }
  getBookingList(path: string): Observable<any> {
  		return this.http.get(path, { headers: this.userHeaderToken() }).pipe(share())
  }
  putBookingList(path: string): Observable<any> {
    return this.http.put(path,null ,{ headers: this.userHeaderToken() }).pipe(share())
  }
}
