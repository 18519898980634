import { ActivatedRoute, Router } from '@angular/router';
import { ToastrCustomService } from './../common-service/toastr.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from '../common-service/common.service';
import { TokenService } from '../common-service/token.service';
import * as _ from 'lodash';
import * as moment from 'moment';
declare var $: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit { 
  PartyName :string;
  Adults:number;
   Child :number;
  Email :string;
  Phone :string;
Rooms :number;
  Message :string;
  FromDate:any=moment().add('days',1).format('YYYY-MM-DD');
 ToDate :any= moment().format('YYYY-MM-DD');
submitClick : boolean =false
  homeUrl: any;
  loginID: any;
  password: any;
  confirmPassword: any;
  newUser: boolean;
  loginIdFilled: boolean = false;
  submitbutton: boolean = false;
  pswMisMatch: boolean = false;
  validate: boolean = true;
  loggedIn: boolean = false;
  userName: any;
  hotelmapurl:any;
  HotelId:number;
  hoteldata:any;
  today: any=moment().format('YYYY-MM-DD')
  constructor(
    public commonService: CommonService,
    public toastrService: ToastrCustomService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public tokenService: TokenService
  ) {
    this.loggedIn = _.isEmpty(sessionStorage.getItem('user_token'))  ? false : true;
    this.userName = sessionStorage.getItem('userName');
    this.loginID = sessionStorage.getItem('loginId');
    this.commonService.loginId = this.loginID;
    this.commonService.isLoginStatus().subscribe((obj) => {
      this.loggedIn = obj.loginStatus;
      this.userName = obj.userName;
      this.loginID = obj.loginId;
      this.hotelmapurl=commonService?.hotelDetails?.Hotelmap;   
    });
  }

  ngOnInit() {  
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.Token) {
        sessionStorage.setItem('Token', params.Token);
      
       
      } else if (params._hid) {
        // this.getHotelinfo(params._hid);
        this.commonService.arrival = params._f;
        this.commonService.departure = params._t;
      }
    });
    this.homeUrl = this.commonService.homeUrl;
    
  }

  changeCurrency(Id, symbol, rate) {
    console.log(rate);
    this.commonService.currencyChange(Id, symbol, rate);
    this.commonService.conversionRate = rate;
  }
  navigateTo(event:any)
  {
    const property=this.commonService?.DetailPro.find((f)=>{console.log('f',f);return f.HotelId==event.target.value});
    if(property){

      window.location.href=property.Url;
    }

  }
  getLoginStatus() {
    console.log(this.loginID);
    this.commonService.getLoginStatus(this.loginID).subscribe((d) => {
      if (d.Status === 0 && d.Data) {
        this.loginIdFilled = true;
        if (d.Data.FirstLogin === 1) {
          this.newUser = true;
        }
        if (d.Data.FirstLogin === 0) {
          this.newUser = false;
        }
      }
    });
  }

  login() {
    if (this.password) {
      this.commonService
        .getLoginStatus(this.loginID, this.password)
        .subscribe((d) => {
          if (d.Status === 0) {
            this.commonService.loginDetail(this.loginID, true, d.Data.Name);
            sessionStorage.setItem('userName', d.Data.Name);
            sessionStorage.setItem('loginId', this.loginID);
            sessionStorage.setItem('user_token', d.Data.Token);
            this.commonService.loginId = this.loginID;
            this.toastrService.showSuccess('', 'login Succesfull');
            $('#login_dropdown').dropdown('hide');
          } else {
            this.toastrService.showError('', d.Description);
          }
        });
    } else {
      this.validate = false;
    }
  }

  registerUser() {
    if (this.password && this.confirmPassword) {
      if (this.password.trim() === this.confirmPassword.trim()) {
        this.commonService
          .registerNewUser(this.loginID, this.password)
          .subscribe((d) => {
            if (d.Status === 0) {
              this.commonService.loginDetail(this.loginID, true, d.Data.Name);
              sessionStorage.setItem('userName', d.Data.Name);
              sessionStorage.setItem('loginId', this.loginID);
              sessionStorage.setItem('user_token', d.Data.Token);
              this.commonService.loginId = this.loginID;
              this.toastrService.showSuccess('', 'Succesfully Registered');
              $('#login_dropdown').dropdown('hide');
            } else {
              this.toastrService.showError('', d.Description);
            }
          });
      } else {
        this.pswMisMatch = true;
      }
    } else {
      this.validate = false;
    }
  }

  match(evt) {
    console.log(evt);
    this.validate = true;
    if (this.password.trim() !== evt.trim()) {
      this.pswMisMatch = true;
    } else {
      this.pswMisMatch = false;
    }
  }

  logout() {
    sessionStorage.removeItem('loginId');
    sessionStorage.removeItem('userName');
    sessionStorage.removeItem('user_token');
    this.loggedIn = false;
    this.loginIdFilled = false;
    this.loginID = '';
    this.userName = '';
    this.password = '';
    this.toastrService.showSuccess('', 'Succesfully logged Out');
  }

  cancelBooking() {
    if (this.loggedIn === true) {
      this.router.navigate(['cancelBookingList']);
    } else {
      this.toastrService.showError('', 'Please Login First');
    }
  }
  submiten() {
		this.submitClick = true
		if(this.PartyName && this.Phone && this.Email && this.Adults && this.Child&& this.Rooms ){			
			let body = {
			  Id: 0,
			  PartyName: this.PartyName,
			  Email:this.Email ,
			  Phone:this.Phone,
			  Adults: this.Adults,
			  Child: this.Child,
			  Rooms: this.Rooms,
			  Message: this.Message,
			  FromDate: this.FromDate,
			  ToDate:this.ToDate
			}
		   
			this.commonService.Bookingenquiry(body).subscribe({next:(res) => {
			  if(res.Status === 0){
				this.toastrService.showSuccess('',res.Message)
				} else{
				  this.toastrService.showError('',res.Message)
			  }
			}})
		  }
      else
      {
        alert("Select requird filed..")
      }
		
	}
departureDate1(evt?) {	
  this.FromDate = moment(this.ToDate,'YYYY-MM-DD').add('days',1).format('YYYY-MM-DD')
  
}
getNext(date){
  return moment(date,'YYYY-MM-DD').add('days',1).format('YYYY-MM-DD')
}
  
}
