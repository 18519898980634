<div class="acc_bnr_wrap addreview_pnl" style="margin-top: 70px;">
  <div class="vres-renview-wrap">
      <div class="vres-prof-cnt">
          <h4>{{commonService.address?.name}}</h4>
          <span class="vres-star">
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
            </span>
          <p>
            <span><i class="fa fa-map-marker"></i>{{commonService.address?.address}},{{commonService.address?.city}},{{commonService.address?.state}},{{commonService.address?.country}}</span>
                                  <span *ngIf="commonService.address?.mobileNo.length>0"><i class="fa fa-phone"></i>{{commonService.address?.mobileNo}}</span>
                                  <span *ngIf="commonService.address?.mobileNo.email>0"><i class="fa fa-envelope"></i><a class="emailtohotel">{{commonService.address?.email}}</a></span>
                          </p>
      </div>
  </div>
</div>


<form name="cancelbookinglistfrm" method="post" id="cancelbookinglistfrm">
  <!-- <input type="hidden" name="resno" value="RES2282" id="resno">
  <input type="hidden" name="email" value="syed@gmail.com" id="email"> -->
<div  class="acc_page-sect cancelbooking-wrap">
  <div class="container">
      <h1 class="acc-bk-title">Cancel Booking List</h1>

       <div class="vresBkList-wrap ">

          <div *ngFor="let list of bookingList" class="vres-book-list">
            <h3>Transaction Date : {{list.BookingDate}}</h3>

              <span *ngIf="list.Status ==='Confirm'" class="booking-tag pending">confirmed Booking</span>
              <span *ngIf="list.Status ==='Not Confirm'"class="booking-tag pending">Unconfirmed Booking Inquiry</span>
              <span *ngIf="list.Status ==='Cancelled'"class="booking-tag pending">Cancelled Booking Inquiry</span>
              <ng-container *ngIf="list.Status ==='Cancelled'">
              <del >
              <h3>{{list.Room}}<small></small></h3>
              <div class="vres-book-cnt">
                  <p>
                      <span class="resNo-span"><b>Res No.: </b>{{list.TransId}}</span>
                      <span class="pax-span"><b>Pax: </b>{{list.Adults}}Adults,{{list.Childs}} child</span>
                      <span class="stay-span"><b>Stay: </b>{{list.Dates}}</span>
                  </p>
                  <div class="book-rm-rate-wrap">
                      <h2 class="bked-price">Total
                      <span><small>&#8377;</small> {{list.Deposite}}</span>
                      </h2>
                  </div>
              </div>
            </del>
            </ng-container>
            <ng-container *ngIf="list.Status !=='Cancelled'">
            <h3>{{list.Room}}<small></small></h3>
            <div class="vres-book-cnt">
                <p>
                    <span class="resNo-span"><b>Res No.: </b>{{list.TransId}}</span>
                    <span class="pax-span"><b>Pax: </b>{{list.Adults}}Adults,{{list.Childs}} child</span>
                    <span class="stay-span"><b>Stay: </b>{{list.Dates}}</span>
                </p>
                <div class="book-rm-rate-wrap">
                    <h2 class="bked-price">Total
                    <span><small>&#8377;</small> {{list.Deposite}}</span>
                    </h2>
                </div>
                <div class="vres-bkBtn-sect">

              <a *ngIf="list.Status !=='Cancelled'" (click)="cancelProcess(list.Id)" class="vres-btn bt-primary cancel-btn"><i class="fa fa-calendar-times-o"></i> &nbsp;Cancel Booking</a>
                </div>
            </div>
          </ng-container>
          </div>
      </div>
  </div>
</div>
</form>

<div class="modal fade" id="cancel_confirm" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Confirmation</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Are you sure you want to cancel this booking ?
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">No</button>
        <button type="button" class="btn btn-primary" (click)="yesConfirmationCancel()">yes</button>
      </div>
    </div>
  </div>
</div>
